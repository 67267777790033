import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ILoginSchema, LoginSchema } from 'schemas/login.schema'
import {
  AuthenticationService,
  LoginResponseBody,
} from 'services/authentication.service'
import { GoogleBtn } from './GoogleBtn'
import KachLogo from './KachLogo'
import {
  Box,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  FormLabel,
  Button,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { ForgotPasswordModal, MicrosoftBtn, useError } from 'kach-components'
import { buildTestId } from 'kach-commons'

export default function Login() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginSchema>({
    resolver: zodResolver(LoginSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const onSuccess = useCallback((data: LoginResponseBody) => {
    router.push('/')

    if (data && window.dataLayer) {
      console.log('dataLayer push!')

      window.dataLayer?.push({
        event: 'user',
        id: data.user_id,
        email: data.user_email,
        name: data.user_name,
        company: data.company_name,
        companyId: data.company_id,
      })
    }
  }, [])

  const router = useRouter()

  const onLoginLocal = useCallback((data: ILoginSchema) => {
    setIsLoading(true)
    AuthenticationService.loginLocal(data)
      .then(onSuccess)
      .catch(error.handleError)
      .finally(() => setIsLoading(false))
  }, [])

  const ref = useRef<HTMLDivElement>()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const onSignInIdentity = useCallback((access_token: string) => {
    setIsLoading(true)
    AuthenticationService.loginIdentity({
      provider: 'google',
      access_token,
    })
      .then(onSuccess)
      .catch(error.handleError)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Box
      display='flex'
      minH='full'
      flexDir='column'
      justifyContent='center'
      py={12}
      px={[2, 6, null, 8]}
    >
      <Box display='flex' justifyContent='center'>
        <KachLogo size='5rem' variant='primary' />
      </Box>

      <Box mt={8} mx='auto' w={['full', 'md']}>
        <Box bg='white' py={8} boxShadow='md' borderRadius='md' px={[4, 8]}>
          <chakra.form
            id={'login-form'}
            experimental_spaceY={8}
            onSubmit={handleSubmit(onLoginLocal)}
          >
            <FormControl isInvalid={!!errors['user']}>
              <FormLabel>Usuario</FormLabel>
              <Input type='text' {...register('user')} />
              {!!!errors['user'] ? (
                <FormHelperText></FormHelperText>
              ) : (
                <FormErrorMessage>{errors['user'].message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errors['password']}>
              <FormLabel>Contraseña</FormLabel>
              <Input type='password' {...register('password')} />
              {!!!errors['password'] ? (
                <FormHelperText></FormHelperText>
              ) : (
                <FormErrorMessage>
                  {errors['password'].message}
                </FormErrorMessage>
              )}
              <Text
                {...buildTestId('forgot-password')}
                onClick={onOpen}
                cursor='pointer'
                _hover={{
                  textDecor: 'underline',
                }}
              >
                Olvide mi contraseña
              </Text>
            </FormControl>

            <Box>
              <Button
                {...buildTestId('login-btn')}
                form='login-form'
                isLoading={isLoading}
                type='submit'
                w='full'
                colorScheme='brand'
              >
                Ingresar
              </Button>
            </Box>
          </chakra.form>

          <Box mt={6}>
            <Box position='relative'>
              <Box
                display='flex'
                alignItems='center'
                position='absolute'
                top={0}
                bottom={0}
                left={0}
                right={0}
              >
                <Box w='full' borderTop='1px' borderColor='gray.300' />
              </Box>
              <Box
                position='relative'
                display='flex'
                justifyContent='center'
                fontSize='sm'
              >
                <chakra.span bg='white' px={2} color='gray.500'>
                  O también puedes
                </chakra.span>
              </Box>
            </Box>

            <Box mt={4}>
              <VStack spacing={'4'}>
                <GoogleBtn small onAccept={onSignInIdentity} />

                <MicrosoftBtn />
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>
      <ForgotPasswordModal onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}
