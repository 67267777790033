import httpClient from '../http/http-client'
import { ILoginSchema } from '../schemas/login.schema'
import { IPermission } from 'kach-clients'
import { IArtifact } from './case.service'
import { IPagination } from './company.service'
import { CompanyTypes } from 'kach-constants'

export interface IUserRole {
  createdBy?: IUser
  usersCount?: number
  createdAt: string
  id: number
  name: string
  permissions: IPermission[]
}

export interface IUser {
  hasLocalCredentials: boolean
  owner: boolean
  id: number
  profilePhoto: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  username: string
  firstName: string
  lastName: string
  email: string
  verified: boolean
  invitedAt: null
  company: ICompany
  role: IUserRole
}

export interface ICompanyLink {
  name: string
  url: string
  icon: string
}

export interface ICompany {
  workingHoursStart?: string
  workingHoursEnd?: string
  links: ICompanyLink[]
  id: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
  name: string
  ein: string
  domain: string
  type: string
  labs: {
    email: string
    name: string
  }[]
}

export interface IIdentitySignIn {
  provider: 'google'
  access_token: string
}

export interface LoginResponseBody {
  user_name: string
  user_email: string
  access_token: string
  user_id: number
  company_id: number
  company_name: string
  targetURL: string
}

export class AuthenticationService {
  static async loginIdentity(
    input: IIdentitySignIn,
  ): Promise<LoginResponseBody> {
    const response = await httpClient.post('/v1/auth/identity/insurer/login', {
      identity: input,
    })

    return response.data
  }

  static async loginLocal(data: ILoginSchema): Promise<LoginResponseBody> {
    const response = await httpClient.post('/v1/auth/local/insurer/login', data)

    return response.data
  }

  static async me(cookie?: string): Promise<IUser> {
    const response = await httpClient.get('/v1/auth/me', {
      headers: {
        Cookie: cookie,
      },
    })
    return response.data
  }

  static async logout() {
    const response = await httpClient.post('/v1/auth/insurer/logout')

    return response.data
  }

  static async searchUsers(search: string = ''): Promise<IPagination<IUser>> {
    const response = await httpClient.get('/v1/auth/users/search', {
      params: {
        search,
        page: 1,
        limit: 10,
      },
    })

    return response.data
  }
}
