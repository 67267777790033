import axios from 'axios'

axios.defaults.withCredentials = true

export const createAxiosInstance = () =>
  axios.create({
    withCredentials: true,
    baseURL: process.env.NEXT_PUBLIC_KACH_API_BASE_URL,
  })

const httpClient = createAxiosInstance()

export default httpClient
