import { z } from 'zod'

export const REQUIRED_FIELD_MESSAGE = 'Campo requerido!'

const InvitationSchema = z.object({
  email: z.string().min(1, REQUIRED_FIELD_MESSAGE).email('Email invalido!'),
})

export const InvitationsSchema = z.object({
  invitations: z.array(InvitationSchema).min(1, 'Mínimo un usuario!'),
  roleId: z.number().min(1, 'Elige un rol!'),
})

export interface IInvitations extends z.infer<typeof InvitationsSchema> {}
