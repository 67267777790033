import Login from 'components/Login'
import { auth } from 'ssr/auth'
import { compose } from 'ssr/compose'
import { publicRoute } from 'ssr/public-route'

export default function LoginPage() {
  return <Login />
}

export const getServerSideProps = compose(
  (ctx, pageProps, fn) => auth(ctx, pageProps, fn, { partial: true }),
  publicRoute,
)
